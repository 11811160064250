<template>
  <div class="row no-gutters panel-info py-3">
    <div
      class="col-3 px-3 py-2"
      :class="{'border-right-black' : index < data.length - 1}"
      v-for="(info, index) in data"
      :key="'name-' + info.name"
    >
      <h3>
        {{ /Orders/.test(info.name) ? info.value : `Rp ${$helper.format.localeThousand(info.value)}` }}
      </h3>
      <div class="d-flex text-center text-lg-left align-items-end">
        <h5 class="text-grey mb-0">{{ info.name }}</h5>
        <div
          v-if="info.previousValue"
          :class="
            (info.previousValue > info.value
              ? 'badge-danger'
              : 'badge-success') + ' badge d-block ml-auto text-percentage'
          "
        >
          <small>
            <span v-if="info.previousValue > info.value"> - </span>
            <span v-else> + </span>
            {{ $helper.report.percentage(info.value, info.previousValue) }}%
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "description", "data"],
};
</script>

<style scoped>
.badge-danger {
  background-color: #90261f;
}
.badge-success {
  background-color: #75a443;
}
.badge {
  padding-left: 8px;
  padding-right: 8px;
  color: white;
}
.panel-info {
  row-gap: 20px;
  border-top: 1px solid #aaa;
}
</style>