var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('b-card',{staticClass:"shadow border-0"},[_c('h1',{staticClass:"roboto"},[_vm._v("Change Password")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('MyInputFormTextVue',{attrs:{"id":"old-password","label":"Old Password","type":_vm.old_password_type,"classComponent":{ 'is-invalid': _vm.$v.formData.old_password.$error },"invalidMessage":[
              !_vm.$v.formData.old_password.required
                ? 'Old password is required'
                : null
            ]},scopedSlots:_vm._u([{key:"input-group-append",fn:function(){return [_c('b-input-group-text',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.switchVisibility('old_password_type')}}},[_c('fawesome-pro',{staticClass:"icon-black",attrs:{"variant":"far","icon":_vm.old_password_type === 'password' ? 'eye' : 'eye-slash',"alt":"Toogle Password Visibility","title":"Password Visibility","size":"sm"}})],1)]},proxy:true}]),model:{value:(_vm.$v.formData.old_password.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.old_password, "$model", $$v)},expression:"$v.formData.old_password.$model"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('MyInputFormTextVue',{attrs:{"id":"new-password","label":"New Password","type":_vm.new_password_type,"classComponent":{ 'is-invalid': _vm.$v.formData.new_password.$error },"invalidMessage":[
              !_vm.$v.formData.new_password.required
                ? 'New password is required'
                : null,
              !_vm.$v.formData.new_password.passwordPattern
                ? 'New password must be at least have 1 letter and 1 number, min 6 characters, no whitespace'
                : null
            ]},scopedSlots:_vm._u([{key:"input-group-append",fn:function(){return [_c('b-input-group-text',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.switchVisibility('new_password_type')}}},[_c('fawesome-pro',{staticClass:"icon-black",attrs:{"variant":"far","icon":_vm.new_password_type === 'password' ? 'eye' : 'eye-slash',"alt":"Toogle Password Visibility","title":"Password Visibility","size":"sm"}})],1)]},proxy:true}]),model:{value:(_vm.$v.formData.new_password.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.new_password, "$model", $$v)},expression:"$v.formData.new_password.$model"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('MyInputFormTextVue',{attrs:{"id":"confirm-password","label":"Confirm Password","type":_vm.confirm_password_type,"classComponent":{ 'is-invalid': _vm.$v.formData.confirm_password.$error },"invalidMessage":[
              !_vm.$v.formData.confirm_password.required
                ? 'Confirm password is required'
                : null,
              !_vm.$v.formData.confirm_password.sameAsPassword
                ? 'Confirm password must be identical with new password!'
                : null
            ]},scopedSlots:_vm._u([{key:"input-group-append",fn:function(){return [_c('b-input-group-text',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.switchVisibility('confirm_password_type')}}},[_c('fawesome-pro',{staticClass:"icon-black",attrs:{"variant":"far","icon":_vm.confirm_password_type === 'password' ? 'eye' : 'eye-slash',"alt":"Toogle Password Visibility","title":"Password Visibility","size":"sm"},nativeOn:{"click":function($event){return _vm.switchVisibility('#input-confirm-password')}}})],1)]},proxy:true}]),model:{value:(_vm.$v.formData.confirm_password.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.confirm_password, "$model", $$v)},expression:"$v.formData.confirm_password.$model"}})],1)],1),_c('div',{staticClass:"d-flex justify-content-end mt-2"},[_c('MyButton',{staticClass:"ml-auto",attrs:{"text":"Save","type":"submit","isLoading":_vm.isLoading}}),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }