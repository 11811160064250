import Vue from "vue";
import axios from "axios";
import store from "./store"
import dashboard from "@/api/dashboard";
import user from "@/api/user";
import order from "@/api/order";
import orderReport from "@/api/orderReport";

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType
  }
  const token = store.getters['user/token']
  if (token) headers.token = token
  return { headers }
}

const apiFactories = {
  dashboard: dashboard(axios, headers),
  user: user(axios, headers),
  order: order(axios, headers),
  orderReport: orderReport(axios, headers),
}

Vue.prototype.$api = apiFactories;