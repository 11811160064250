<template>
  <div class="container-fluid">
    <b-card class="shadow border-0">
      <b-skeleton-wrapper :loading="isLoading">
        <template #loading>
          <ShimmerDetailOrderCustomer />
        </template>
        <h1 class="roboto">Sales Order Detail</h1>
        <b-card class="mt-2" v-if="order && customer">
          <h2 class="mb-2 roboto">Detail Order</h2>
          <LabelCustomer name="Order No" :value="order.order_number" />
          <LabelCustomer name="Customer" :value="customer.full_name" />
          <LabelCustomer
            v-if="order.reseller_membership && order.reseller_membership.name"
            name="Membership"
            :value="order.reseller_membership.name"
          />
          <LabelCustomer name="Email" :value="customer.email" />
          <LabelCustomer name="Date" :value="order.order_date" />
          <LabelCustomer name="Commission" :value="`${$helper.format.price(order.platform_commission_rate)}`" />
          <!-- <LabelCustomer name="Commission" :value="`Rp ${$helper.format.localeThousand(getTotalPlatformCommission(order))}`" /> -->
          <LabelCustomer
            name="Total"
            :value="$helper.format.price(order.subtotal)"
          />
          <template v-if="order.voice_message">
            <b-row class="my-2">
              <b-col cols="3">
                <b>Voice Message</b>
              </b-col>
              <b-col>
                <b-img
                  :src="order.voice_message.url_qr_code"
                  width="200"
                  height="200"
                />
              </b-col>
            </b-row>
            <LabelCustomer
              name="Email Send To"
              :value="order.voice_message.email"
            />
          </template>
          <LabelCustomer name="Notes" :value="order.email_notif" />
        </b-card>
        <b-card class="mt-3" v-if="order">
          <h2 class="mb-2 roboto">Detail Product</h2>
          <b-card
            class="mt-2"
            v-for="salesOrderSeller in order.sales_order_sellers"
            :key="'sales_order_seller' + salesOrderSeller.id"
          >
            <div class="row">
              <div class="col">
                {{ salesOrderSeller.seller_name }}
              </div>
              <div class="col text-right">
                <b>{{ salesOrderSeller.shipping_name }}</b>
              </div>
            </div>
            <div class="row mt-1 text-sm">
              <div class="col">Status: {{ salesOrderSeller.status_text }}</div>
              <div class="col text-right" v-if="salesOrderSeller.sent_date">
                Sent Date: {{ salesOrderSeller.sent_date }}
              </div>
            </div>
            <div class="row mt-1" v-if="salesOrderSeller.shipment_type == '1'">
              <div class="col-2">
                <b>Pickup Point</b>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col">
                    <p>{{ salesOrderSeller.pick_up_point_name }}</p>
                    <p>
                      {{ salesOrderSeller.pick_up_point_address }}
                    </p>
                    <p>
                      {{ salesOrderSeller.pick_up_point_contact }}
                    </p>
                  </div>
                  <div class="col">
                    <p>
                      Duration:
                      {{ salesOrderSeller.pick_up_point_duration }} Day(s)
                    </p>
                    <p>
                      Latitude: {{ salesOrderSeller.pick_up_point_latitude }}
                    </p>
                    <p>
                      Longitude: {{ salesOrderSeller.pick_up_point_longitude }}
                    </p>
                    <p>
                      <a
                        :href="
                          `https://maps.google.com/maps?q=` +
                          salesOrderSeller.pick_up_point_latitude +
                          ',' +
                          salesOrderSeller.pick_up_point_longitude
                        "
                      >
                        Open with GMaps
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <OrderProductCard
                v-for="salesOrderDetail in salesOrderSeller.sales_order_details"
                :key="salesOrderDetail.id"
                :product_name="salesOrderDetail.product_name"
                :price="salesOrderDetail.price"
                :quantity="salesOrderDetail.quantity"
                :product_image_url="salesOrderDetail.product.thumbnail_url"
                :size="salesOrderDetail.product_var.size.eu"
                :color="salesOrderDetail.product_var.color.name"
                :notes="salesOrderSeller.email_notif"
                :merchant="salesOrderSeller.merchant"
                :vendor="salesOrderDetail.product.vendor"
                :platform_commission_rate="salesOrderDetail.platform_commission_rat"
                :platform_commission_value="salesOrderDetail.platform_commission_val"
              />
            </div>
            <div class="mt-2">
              <b>Tracking Status</b>
              <b-table-simple class="mt-1"
                hover
                small
                striped
                responsive
                borderless
              >
                <b-thead>
                  <b-tr class="border-bottom">
                    <b-th>Date</b-th>
                    <b-th>Status</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="salesOrderSellerStatus in salesOrderSeller.sales_order_seller_statuses"
                    :key="salesOrderSellerStatus.id"
                    class="table-row-style"
                  >
                    <b-td>{{ salesOrderSellerStatus.date }}</b-td>
                    <b-td>{{ salesOrderSellerStatus.description }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </b-card>
        </b-card>
        <b-card class="mt-3">
          <div class="row" v-if="order">
            <div class="col">
              <h2 class="mb-3 roboto">Shipping Address</h2>
              <span class="line-height-address">
                {{ order.shipment_address.name }} <br />
                {{ order.shipment_address.address }} <br />
                {{ order.shipment_address.township.name }} -
                {{ order.shipment_address.district.name }} -
                {{ order.shipment_address.region.name }} <br />
                {{ order.shipment_address.postal_code }} <br />
                {{ order.shipment_address.phone }}
              </span>
            </div>
            <div class="col">
              <h2 class="mb-3 roboto">Payment Address</h2>
              <span class="line-height-address">
                {{ order.billing_address.name }}
                <br />
                {{ order.billing_address.address }}
                <br />
                {{ order.billing_address.township.name }} -
                {{ order.billing_address.district.name }} -
                {{ order.billing_address.region.name }}
                <br />
                {{ order.billing_address.postal_code }}
                <br />
                {{ order.billing_address.phone }}
              </span>
            </div>
            <div class="col" v-if="order">
              <h2 class="mb-3 roboto">Total</h2>
              <b-row class="mt-2">
                <b-col>Sub Total</b-col>
                <b-col class="text-right">
                  {{ $helper.format.price(order.total_price) }}
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>Discount</b-col>
                <b-col class="text-right">
                  {{ $helper.format.price(order.discount) }}
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>Voucher</b-col>
                <b-col class="text-right">
                  {{ $helper.format.price(order.voucher) }}
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>Shipping</b-col>
                <b-col class="text-right">
                  {{ $helper.format.price(order.shipping) }}
                </b-col>
              </b-row>
              <!-- <b-row class="mt-2">
                <b-col>Loyalty Point</b-col>
                <b-col class="text-right">
                  {{
                    $helper.format.thousands(
                      order.loyalty_point_used
                        ? order.loyalty_point_used.point
                        : 0
                    )
                  }}
                </b-col>
              </b-row> -->
              <b-row class="mt-2">
                <b-col>Handling Fee</b-col>
                <b-col class="text-right">
                  {{ $helper.format.price(order.handling_fee) }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col>Total</b-col>
                <b-col class="text-right text-gold roboto-bold">
                  {{ $helper.format.price(order.subtotal) }}
                </b-col>
              </b-row>
              <b-row
                v-if="
                  order.sales_order_payments &&
                  order.sales_order_payments.length > 0 &&
                  order.sales_order_payments[0].instalment_cost &&
                  order.sales_order_payments[0].instalment_cost > 0
                "
              >
                <b-col>
                  <b-row>
                    <b-col>Installment Cost</b-col>
                    <b-col>
                      {{ order.sales_order_payments[0].instalment_cost }}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>Total Installment</b-col>
                    <b-col>
                      {{
                        order.subtotal +
                        order.sales_order_payments[0].instalment_cost
                      }}
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card>
        <div class="d-flex justify-content-end mt-2">
          <b-button
            size="sm"
            variant="secondary"
            @click="onCancel()"
          >
            Cancel
          </b-button>
        </div>
      </b-skeleton-wrapper>
    </b-card>
  </div>
</template>

<script>
import LabelCustomer from "./components/LabelCustomer.vue";
import OrderProductCard from "./components/OrderProductCard.vue";
import ShimmerDetailOrderCustomer from "./components/ShimmerDetailOrderCustomer.vue";
// import MyButton from "../../../components/pages/MyButton.vue";
export default {
  components: {
    LabelCustomer,
    OrderProductCard,
    ShimmerDetailOrderCustomer,
    // MyButton,
  },
  data() {
    return {
      order: null,
      customer: null,
      isLoading: false,
    };
  },
  computed: {
    dataId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      this.isLoading = true;
      try {
        const res = await this.$api.order.getDetailData(this.dataId);
        if (res.status === 200) {
          const d = res.data.data;
          this.customer = d.member;
          this.order = d;
        } else {
          this.$helper.toaster.show(res.data.message, "danger");
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
        this.$helper.toaster.show(error.response.data.message, "danger");
      }
    },
    onCancel() {
      this.$router.push("/orders");
    },
    getTotalPlatformCommission(order) {
      return order.platform_commission_rate ? (order.subtotal - order.handling_fee - order.shipping) * ((100 - order.platform_commission_rate) / 100) : '-';
    },
  },
};
</script>

<style scoped>
.line-height-address {
  line-height: 1.4;
}
</style>