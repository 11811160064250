import link from "./link";
import store from "../store";
import { helperFactories } from "../helper"
 

export default (axios, headers) => ({
  async login(data) {
    try {
      const response = await axios.post(process.env.VUE_APP_API_URL + link.user.login, data, headers());
      if (response.status === 200) {
        store.dispatch("user/authLogin", response.data.data);
        helperFactories.cookie.put("profile", JSON.stringify(response.data.data))
      }
      return response;
    } catch (error) {
      console.error(error)
      return error.response;
    }
  },
  async logout() {
    try {
      const res = await axios.post(process.env.VUE_APP_API_URL + link.user.logout, {}, headers());
      helperFactories.cookie.remove("profile");
      store.dispatch("user/authLogin");
      return res
    } catch (error) {
      return error.response;
    }
  },
  async detail() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + link.user.detail, headers());
    } catch (error) {
      return error.response;
    }
  },
  async changePassword(data) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + link.user.changePassword, data, headers())
    } catch (error) {
      return error.response;
    }
  },
  async updateCallbackUrl(data) {
    try {
      return await axios.patch(process.env.VUE_APP_API_URL + link.user.updateCallbackUrl, data, headers())
    } catch (error) {
      return error.response;
    }
  },
})