import { helperFactories } from "@/helper"

const state = () => {
  const profile = ((user) => {
    try {
      return JSON.parse(user)
    } catch (e) {
      return null
    }
  })(helperFactories.cookie.get("profile"))
  return {
    profile: profile,
    token: profile?.token,
  }
}

const getters = {
  isLogin: (state) => state.token != null && state.token != undefined ? true : false,
  profile: (state) => state.profile,
  token: (state) => state.token,
}

const actions = {
  authLogin({ commit }, data) {
    commit("setData", { key: "profile", value: data });
    commit("setData", { key: "token", value: data?.token });
  }
}

const mutations = {
  setData(state, { key, value }) {
    state[key] = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}