<template>
  <b-form-group
    :id="'input-form-group-' + id"
    :label-for="'input-' + id"
    :description="description"
  >
    <template #label>
      {{ label }} <span v-if="$attrs.hasOwnProperty('required') && $attrs.required != null" class="text-danger">*</span>
    </template>
    <b-input-group :id="`input-group-${id}`">
      <b-input-group-prepend v-if="hasInputGroupAppendPrepend">
        <slot name="input-group-prepend"></slot>
      </b-input-group-prepend>
      <textarea
        v-if="type == 'textarea'"
        :id="'input-' + id"
        v-model="model"
        :placeholder="placeholder"
        class="form-control"
        rows="4"
        :class="classComponent"
        :disabled="disabled"
      />
      <vue-ckedit
        v-else-if="type == 'ckeditor'"
        :id="'input-' + id" 
        v-model="model"
        :placeholder="placeholder"
        :class="classComponent"
        :disabled="disabled"
      />
      <b-form-input
        v-else
        :id="'input-' + id"
        v-model="model"
        @blur="$emit('blur')"
        :type="type != null ? type : 'text'"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="classComponent"
      ></b-form-input>
      <b-input-group-append v-if="hasInputGroupAppendPrepend">
        <slot name="input-group-append"></slot>
      </b-input-group-append>
      <div
        class="invalid-feedback text-right"
        v-if="invalidMessage && invalidMessage.length > 0"
      >
        <div
          v-for="(message, index) in invalidMessage"
          :key="id + '-' + message + '-' + index"
        >
          {{ message }}
        </div>
      </div>
    </b-input-group>
  </b-form-group>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "MyInputFormText",
  props: [
    "value",
    "id",
    "label",
    "placeholder",
    "description",
    "classComponent",
    "type",
    "invalidMessage",
    "disabled",
  ],
  computed: {
    model: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit("input", value);
      }
    },
    hasInputGroupAppendPrepend() {
      return this.$slots['input-group-append'] || this.$slots['input-group-prepend'];
    }
  },
};
</script>
