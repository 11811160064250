<template>
  <header class="sticky-top">
    <b-navbar
      toggleable="lg"
      type="dark"
      class="pl-0 justify-content-start navbar-color navbar-min-height"
    >
      <b-navbar-toggle
        target="dashboard-sidebar"
        v-b-toggle.dashboard-sidebar
      />
      <b-navbar-brand to="/" class="d-inline-flex align-item-center">
        <b-img
          :src="require('@/assets/img/logotype_white_background.svg')"
          alt="Whizliz"
          width="120"
          class="invert ml-lg-3"
        />
      </b-navbar-brand>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto align-items-center">
          <b-nav-item-dropdown right no-caret>
            <template #button-content>
              <div class="d-flex align-items-center">
                <fawesome-pro
                  variant="far"
                  icon="user"
                  class="text-white cursor-pointer icon-size pr-2"
                  alt="Chat"
                  title="Chat"
                />
                <div class="d-none d-lg-inline-block text-white">
                  {{ profile?.name }}
                </div>
              </div>
            </template>
            <b-dropdown-item to="/change-password">Change password</b-dropdown-item>
            <b-dropdown-item to="/callback-url">Set Callback URL</b-dropdown-item>
            <b-dropdown-item @click="logout">Logout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-sidebar id="dashboard-sidebar" backdrop>
      <Sidebar />
    </b-sidebar>
    <LoadingOverlay v-show="isLoadingLogout" />
  </header>
</template>
<script>
const LoadingOverlay = () => import("@/components/LoadingOverlay.vue");
const Sidebar = () => import('@/components/navs/Sidebar.vue');
import { mapGetters } from "vuex";
export default {
  name: "Header",
  components: {
    LoadingOverlay,
    Sidebar,
  },
  data() {
    return {
      isLoadingLogout: false,

    }
  },
  computed: {
    ...mapGetters("user", {
      profile: "profile",
    }),
  },
  methods: {
    async logout() {
      try {
        this.isLoadingLogout = true;
        const res = await this.$api.user.logout()
        if (res.status === 200) {
          this.$helper.toaster.show(res.data.message, 'success')
          this.$router.replace('/login')
        } else this.$helper.toaster.show(res.data.message, 'danger')
        this.isLoadingLogout = false;
      } catch (e) { 
        this.isLoadingLogout = false;
        console.error(e);
      }
    },
  }
};
</script>
<style scoped>
.icon-size {
  font-size: 1.4em;
}
.btn-seller-help {
  border: 1px solid white;
  background: #888;
  color: white;
  font-size: 0.765rem;
  transition: 0.5s all;
}
.btn-seller-help:hover {
  border: 1px solid #d89700;
  background: #d89700;
  color: white;
}
@media (min-width: 992px) {
  header > nav.navbar {
    min-height: 100px;
  }
}
</style>
