import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

// ? LOGIN
const Login = () => import(/* webpackPrefetch: true */ '../views/Login.vue');
// ? DAHSBOARD
const Dashboard = () => import(/* webpackPrefetch: true */ '../views/pages/dashboard/Dashboard.vue');
// ? ORDER
const ListOrder = () => import(/* webpackPrefetch: true */ '../views/pages/orders/ListOrder.vue');
const DetailOrder = () => import(/* webpackPrefetch: true */ '../views/pages/orders/DetailOrder.vue');
// ? ORDER REPORT
const ListOrderReport = () => import(/* webpackPrefetch: true */ '../views/pages/reports/ListOrderReport.vue');

// ? CHANGE PASSWORD
const changePassword = () => import(/* webpackPrefetch: true */ '../views/pages/profile/ChangePassword.vue');
// ? CALLBACK PAYMENT URL
const callbackUrl = () => import(/* webpackPrefetch: true */ '../views/pages/profile/CallbackUrl.vue');

Vue.use(VueRouter)

const credential = () => {
  const profile = store.getters['user/isLogin'] && store.getters['user/profile'] ? store.getters['user/profile'] : null
  if (profile) {
    const expire = new Date(profile.expired_date)
  expire.setHours(expire.getHours() - 7)
    if (expire <= new Date()) return null
  }
  return profile
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true } 
  },
  {
    path: '/orders',
    name: 'ListOrder',
    component: ListOrder,
    meta: { requiresAuth: true } 
  },
  {
    path: "/orders/detail/:id",
    name: 'DetailOrder',
    component: DetailOrder,
    meta: { requiresAuth: true } 
  },
  {
    path: "/reports",
    name: 'ListOrderReport',
    component: ListOrderReport,
    meta: { requiresAuth: true } 
  },
  {
    path: "/change-password",
    name: 'ChangePassword',
    component: changePassword,
    meta: { requiresAuth: true } 
  },
  {
    path: "/callback-url",
    name: 'CallbackUrl',
    component: callbackUrl,
    meta: { requiresAuth: true } 
  },
  {
    path: "*",
    redirect: "/"
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router;

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    // this route requires auth, check credential exist
    if (credential()) next();
    // if not, redirect to login page.
    else next('/login');
  } else {
    if (credential()) next("/");
    else next();
  }
})
