<template>
  <div class="container-fluid">
    <b-card class="shadow border-0">
      <MyTableVue
        :fields="table.fields"
        :items="table.items"
        :busy="table.isLoading"
        :selected="table.selected"
        :selectable="table.selectable"
        :sortBy="table.sortBy"
        :sortDesc="table.sortDesc"
        :currentPage="table.currentPage"
        :perPage="table.perPage"
        :totalRows="table.totalRows"
        @onSelected="onSelected"
        @onSortBy="onSortBy"
        @onSortDesc="onSortDesc"
        @onChangeCurrentPage="onChangeCurrentPage"
        @onChangePerPage="onChangePerPage"
      >
        <template #header>
          <h2 class="roboto">List Order</h2>
          <div class="row">
            <div class="ml-auto mb-2 col-3">
              <MySearchVue placeholder="Search by Invoice" v-model="search" />
            </div>
          </div>
          <div class="row">
            <div class="ml-auto mb-3 col-3">
              <b-form-select
                v-model="selectedFilter"
                :options="listFilter"
              ></b-form-select>
            </div>
          </div>
        </template>

        <template #no="{ index }">
          {{ index + 1 + (table.currentPage - 1) * table.perPage }}
        </template>

        <template #order_date="{ value }">
          {{ $helper.format.todayYesterday(value) }}
        </template>

        <template #handling_fee="{ value }">
          {{ $helper.format.localeThousand(value) }}
        </template>

        <template #shipping="{ value }">
          {{ $helper.format.localeThousand(value) }}
        </template>

        <template #subtotal="{ value }">
          {{ $helper.format.localeThousand(value) }}
        </template>

        <template #platform_commission_rate="{ value }">
          {{ $helper.format.localeThousand(value) }}
        </template>

        <!-- <template #platform_commission_rate="{ value }">
          {{ `${value}%` }}
        </template>

        <template #header_total_platform_commission="data">
          <span>{{ data.label }}</span>
          <fawesome-pro
            variant="far"
            icon="question-circle"
            alt="Info"
            class="ml-2 cursor-pointer text-info"
            v-b-tooltip.hover="{ customClass: 'tooltip-commission' }"
            title="(Total - Handling Fee - Shipping) * (100% - Commission Rates)"
          />
        </template>

        <template #total_platform_commission="{ item }">
          {{ $helper.format.localeThousand(getTotalPlatformCommission(item)) }}
        </template> -->

        <template #actions="{ item }">
          <fawesome-pro
            variant="far"
            icon="eye"
            class="p-1 icon-black cursor-pointer hover-success"
            :to="`/orders/detail/${item.id}`"
            alt="View"
            title="View"
          />
        </template>
      </MyTableVue>
    </b-card>
  </div>  
</template>

<script>
const MySearchVue = () => import("@/components/pages/MySearch.vue");
const MyTableVue = () => import("@/components/pages/MyTable.vue");

export default {
  name: "ListOrder",
  components: {
    MyTableVue,
    MySearchVue,
  },
  data() {
    return {
      downloadReady: false,
      search: null,
      selectedData: null,
      table: {
        currentPage: 1,
        perPage: 10,
        totalRows: 0,
        fields: [
          { key: "no", sortable: false, custom_column: true },
          { key: "order_number", label: "Invoice", sortable: true },
          { key: "order_date", tdClass: "text-nowrap", sortable: true, custom_column: true },
          { key: "member.email", label: "Customer" },
          { key: "payment_type", label: "Payment Method" },
          { key: "handling_fee", label: "Handling Fee (Rp)", custom_column: true },
          { key: "shipping", label: "Shipping (Rp)", custom_column: true },
          { key: "subtotal", label: "Total (Rp)", custom_column: true },
          { key: "platform_commission_rate", label: "Commission (Rp)", thClass: "text-nowrap", custom_column: true},
          // { key: "platform_commission_rate", label: "Commission Rates", custom_column: true, custom_header: true },
          // { key: "total_platform_commission", label: "Commission (Rp)", thClass: "text-nowrap", custom_column: true, custom_header: true },
          { key: "status_text", label: "Payment Status" },
          { key: "actions", custom_column: true },
        ],
        items: [],
        selected: [],
        selectable: false,
        sortBy: "order_date",
        sortDesc: true,
        isLoading: false,
      },
      selectedFilter: null,
      listFilter: [
        {
          value: null,
          text: "All",
        },
        {
          label: "Status",
          options: [
            { value: { status: "paid" }, text: "Paid" },
            {
              value: { status: "unpaid" },
              text: "Unpaid",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.table.isLoading = true;
      try {
        const res = await this.$api.order.getData({
          limit: this.table.perPage,
          offset: this.table.currentPage - 1,
          sort: this.table.sortBy,
          order: this.table.sortDesc ? "DESC" : "ASC",
          search: this.search,
          ...(this.selectedFilter ? this.selectedFilter : undefined),
        });
        if (res.status === 200) {
          const d = res.data;
          this.table.items = d.data;
          this.table.totalRows = d.meta.total;
        } else this.$helper.toaster.show("Something went wrong", "danger");
        this.table.isLoading = false;
      } catch (error) {
        console.error(error);
        this.table.isLoading = false;
      }
    },
    onSelected(value) {
      this.table.selected = value;
    },
    onSortBy(value) {
      this.table.sortBy = value;
    },
    onSortDesc(value) {
      this.table.sortDesc = value;
    },
    onSearch(value) {
      console.log("search: ", value);
    },
    onAddClick() {
      console.log("onAddClick");
    },
    onChangeCurrentPage(value) {
      this.table.currentPage = value;
      console.log("onChangeCurrentPage", value);
    },
    onChangePerPage(value) {
      this.table.perPage = value;
    },
    async sendEmailVoiceMessage(id) {
      this.table.isLoading = true;
      const data = JSON.parse(JSON.stringify({ id: id }));
      try {
        let res;
        res = await this.$api.orders.sendEmailVoiceMessage(data);
        if (res.status === 200) {
          this.$helper.toaster.show(res.data.message, "success");
        } else {
          this.$helper.toaster.show(res.data.message, "danger");
        }
        this.table.isLoading = false;
      } catch (error) {
        this.table.isLoading = false;
        console.error(error);
        this.$helper.toaster.show(error.response.data.message, "danger");
      }
    },
    getTotalPlatformCommission(item) {
      return item.platform_commission_rate ? (item.subtotal - item.handling_fee - item.shipping) * ((100 - item.platform_commission_rate) / 100) : '-';
    },
  },
  watch: {
    search() {
      this.fetchData();
    },
    selectedFilter() {
      this.fetchData();
    },
    "table.currentPage"() {
      this.fetchData();
    },
    "table.perPage"() {
      this.fetchData();
    },
    "table.sortBy"() {
      this.fetchData();
    },
    "table.sortDesc"() {
      this.fetchData();
    },
  },
};
</script>