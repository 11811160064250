<template>
  <div class="container-fluid">
    <b-card class="shadow border-0">
      <MyTableVue
        :fields="table.fields"
        :items="table.items"
        :busy="table.isLoading"
        :selected="table.selected"
        :selectable="table.selectable"
        :sortBy="table.sortBy"
        :sortDesc="table.sortDesc"
        :currentPage="table.currentPage"
        :perPage="table.perPage"
        :totalRows="table.totalRows"
        @onSelected="onSelected"
        @onSortBy="onSortBy"
        @onSortDesc="onSortDesc"
        @onChangeCurrentPage="onChangeCurrentPage"
        @onChangePerPage="onChangePerPage"
      >
        <template #header>
          <b-row>
            <b-col>
              <h2 class="roboto">List Order Report</h2>
            </b-col>
            <!-- <b-col class="d-flex align-items-center justify-content-end">
              <MyButtonVue 
                text="XLSX"
                @onClick="onDownloadReport()"
                :isLoading="isLoadingDownloadXlsx"
              >
                <template #prefix>
                  <fawesome-pro
                    variant="far"
                    icon="arrow-alt-to-bottom"
                    class="mr-1"
                    alt="Download"
                    title="Download"
                  />
                </template>
              </MyButtonVue>
            </b-col> -->
          </b-row>
          <b-row class="mb-2">
            <b-col cols="3" class="ml-auto">
              <MySearchVue placeholder="Search by Invoice" v-model="search" />
            </b-col>
          </b-row>
          <!-- <b-row class="mb-2">
            <b-col cols="3" class="ml-auto">
              <b-form-select v-model="selectedFilter" :options="listFilter" />
            </b-col>
          </b-row> -->
          <b-form inline>
            <b-form-select v-model="selectedFilter" :options="listFilter" class="ml-auto mr-2" />
            <b-form-select
              class="mr-2"
              v-model="selectedDateCategory"
              :options="listDateCategory"
            />
            <MyDatePicker
              v-model="dateReport"
              :showShortcuts="true"
              :isRange="true"
              placeholder="Period"
              class="mr-2"
            />
            <MyButtonVue 
              text="Apply Filter"
              size="md"
              @onClick="fetchData"
              class="mr-2"
            >
              <template #prefix>
                <fawesome-pro
                  variant="far"
                  icon="filter"
                  class="mr-1"
                  alt="Download"
                  title="Download"
                />
              </template>
            </MyButtonVue>
            <MyButtonVue 
              text="XLSX"
              size="md"
              @onClick="onDownloadReport()"
              :isLoading="isLoadingDownloadXlsx"
            >
              <template #prefix>
                <fawesome-pro
                  variant="far"
                  icon="arrow-alt-to-bottom"
                  class="mr-1"
                  alt="Download"
                  title="Download"
                />
              </template>
            </MyButtonVue>
          </b-form>
        </template>

        <template #no="{ index }">
          {{ index + 1 + (table.currentPage - 1) * table.perPage }}
        </template>

        <template #[`sales_order_seller.order_date`]="{ value }">
          {{ $helper.format.todayYesterday(value) }}
        </template>

        <template #[`sales_order_seller.payment_date`]="{ value }">
          {{ $helper.format.todayYesterday(value) }}
        </template>

        <template #price="{ value }">
          {{ $helper.format.localeThousand(value) }}
        </template>

        <template #discount_merchant="{ value }">
          {{ $helper.format.localeThousand(value) }}
        </template>

        <template #discount_whizliz="{ value }">
          {{ $helper.format.localeThousand(value) }}
        </template>

        <template #item_discount="{ item }">
          {{ $helper.format.localeThousand(item.price_discount - item.price) }}
        </template>

        <template #price_discount="{ value }">
          {{ $helper.format.localeThousand(value) }}
        </template>

        <template #header_sub_total="data">
          <span class="mr-2">{{ data.label }}</span>
          <fawesome-pro
            variant="far"
            icon="question-circle"
            alt="Info"
            class="cursor-pointer text-info"
            v-b-tooltip.hover="{ customClass: 'tooltip-commission' }"
            title="Item Final Price (Rp) - Voucher Value (Rp)"
          />
        </template>

        <template #sub_total="{ value }">
          {{ $helper.format.localeThousand(value) }}
        </template>

        <template #voucher_value="{ value }">
          {{ $helper.format.localeThousand(value) }}
        </template>

        <template #shipping="{ value }">
          {{ $helper.format.localeThousand(value) }}
        </template>

        <template #total_with_shipping="{ item }">
          {{ $helper.format.localeThousand(item.shipping + item.sub_total) }}
        </template>

        <template #platform_commission_rate="{ value }">
          {{ value ? `${value}%` : '' }}
        </template>

        <template #header_platform_commission_value="data">
          <span class="mr-2">{{ data.label }}</span>
          <fawesome-pro
            variant="far"
            icon="question-circle"
            alt="Info"
            class="cursor-pointer text-info"
            v-b-tooltip.hover="{ customClass: 'tooltip-commission' }"
            title="Total (Rp) * Commission Rates (%)"
          />
        </template>

        <template #platform_commission_value="{ value }">
          {{ $helper.format.localeThousand(value) }}
        </template>
      </MyTableVue>
    </b-card>
  </div>
</template>

<script>
const MyTableVue = () => import("@/components/pages/MyTable.vue");
const MySearchVue = () => import("@/components/pages/MySearch.vue");
const MyButtonVue = () => import("@/components/pages/MyButton.vue");
const MyDatePicker = () => import("@/components/form/MyDatePicker.vue");

export default {
  name: "ListReport",
  components: {
    MyTableVue,
    MySearchVue,
    MyButtonVue,
    MyDatePicker,
  },
  data() {
    return {
      downloadReady: false,
      search: null,
      selectedData: null,
      table: {
        currentPage: 1,
        perPage: 10,
        totalRows: 0,
        fields: [
          { key: "no", sortable: false, custom_column: true },
          { key: "sales_order_seller.order_number", sortable: true, label: "Invoice"},
          { key: "sales_order_seller.order_date", sortable: true, tdClass: "text-nowrap", label: "Order Date", custom_column: true },
          { key: "sales_order_seller.payment_date", sortable: true, tdClass: "text-nowrap", label: "Payment Date", custom_column: true },
          { key: "sales_order_seller.merchant.full_name", label: "Seller" },
          { key: "sales_order_seller.member.full_name", label: "Customer" },
          { key: "sales_order_seller.member.phone", label: "Phone" },
          { key: "product_name", label: "Item Name" },
          { key: "price", label: "Item Price (Rp)", custom_column: true },
          { key: "item_discount", label: "Item Discount (Rp)", custom_column: true },
          { key: "price_discount", label: "Item Final Price (Rp)", custom_column: true },
          { key: "quantity", label: "Quantity" },
          { key: "voucher_value", label: "Voucher Value (Rp)", custom_column: true },
          { key: "sub_total", label: "Total (Rp)", thClass: "text-nowrap", custom_column: true, custom_header: true },
          { key: "shipping", label: "Shipping (Rp)", custom_column: true },
          { key: "total_with_shipping", label: "Total + Shipping (Rp)", custom_column: true },
          { key: "platform_commission_rate", label: "Commission Rates", custom_column: true },
          { key: "platform_commission_value", label: "Commission (Rp)", thClass: "text-nowrap", custom_column: true, custom_header: true },
          // { key: "total_platform_commission", label: "Commission (Rp)", thClass: "text-nowrap", custom_column: true, custom_header: true },
          { key: "sales_order.payment_type", label: "Payment Method" }, 
          { key: "status_text", label: "Status" },
        ],
        items: [],
        selected: [],
        selectable: false,
        sortBy: "order_date",
        sortDesc: true,
        isLoading: false,
      },
      selectedFilter: null,
      listFilter: [
        {
          value: null,
          text: "All",
        },
        {
          label: "Status",
          options: [
            // { value: { status: "CH" }, text: "Checked Out" },
            // { value: { status: "OV" }, text: "Order Verified" },
            // { value: { status: "PI" }, text: "Pickup" },
            // { value: { status: "PA" }, text: "Packing" },
            // { value: { status: "OD" }, text: "On Delivery" },
            // { value: { status: "RC" }, text: "Received by Customer" },
            // { value: { status: "EX" }, text: "Expire Payment" },
            // { value: { status: "CA" }, text: "Cancelled" },
            
            { value: { status: "PAID" }, text: "Paid" },
            { value: { status: "UNPAID" }, text: "Unpaid" },
          ],
        },
      ],

      selectedDateCategory: { date_category: "order_date" },
      listDateCategory: [
        {
          label: "Filter Date Category",
          options: [
            { value: { date_category: "order_date" }, text: "Order Date" },
            { value: { date_category: "payment_date" }, text: "Payment Date" },
          ],
        },
      ],

      dateReport: null,
      isLoadingDownloadXlsx: false,
    };
  },
  mounted() {
    this.defaultDate();
    this.fetchData();
  },
  methods: {
    defaultDate() {
      const start = new Date();
      const end = new Date();
      start.setTime(start.getTime() - 29 * 24 * 3600 * 1000);
      this.dateReport = [start, end];
    },
    async fetchData() {
      this.table.isLoading = true;
      try {
        const res = await this.$api.orderReport.getData({
          limit: this.table.perPage,
          offset: this.table.currentPage - 1,
          sort: this.table.sortBy,
          order: this.table.sortDesc ? "DESC" : "ASC",
          search: this.search,
          ...(this.selectedFilter ?? undefined),
          ...(this.selectedDateCategory ?? undefined),
          ...(this.dateReport
            ? {
                sdate: this.$helper.format.dateAPI(this.dateReport[0]),
                edate: this.$helper.format.dateAPI(this.dateReport[1]),
              }
            : undefined),
        });
        if (res.status === 200) {
          const d = res.data;
          this.table.items = d.data;
          this.table.totalRows = d.meta.total;
        } else this.$helper.toaster.show("Something went wrong", "danger");
        this.table.isLoading = false;
      } catch (error) {
        console.error(error);
        this.table.isLoading = false;
      }
    },
    async onDownloadReport() {
      this.isLoadingDownloadXlsx = true;
      try {
        const res = await this.$api.orderReport.getData({
          sort: this.table.sortBy,
          order: this.table.sortDesc ? "DESC" : "ASC",
          search: this.search,
          ...(this.selectedFilter ?? undefined),
          ...(this.selectedDateCategory ?? undefined),
          ...(this.dateReport
            ? {
                sdate: this.$helper.format.dateAPI(this.dateReport[0]),
                edate: this.$helper.format.dateAPI(this.dateReport[1]),
              }
            : undefined),
        });
        if (res.status === 200) {
          // const arrData = res.data.data.map((element, index) => {
          //   return {
          //     "No": index + 1,
          //     "Invoice": element.order_number,
          //     "Order Date": this.$helper.format.date(element.order_date, "DD-MMM-YYYY"),
          //     "Payment Date": this.$helper.format.date(element.payment_date, "DD-MMM-YYYY"),
          //     "Seller": element.merchant.full_name,
          //     "Customer": element.member.full_name,
          //     "Phone": element.member.phone,
          //     "Item Name": element.sales_order_detail.product_name,
          //     "Item Price (Rp)": element.sales_order_detail.price,
          //     "Qty": element.sales_order_detail.quantity,
          //     // "Discount Merchant (Rp)": element.sales_order_detail.discount_merchant,
          //     // "Discount Whizliz (Rp)": element.sales_order_detail.discount_whizliz,
          //     "Shipping": element.shipping,
          //     "Total (Rp)": element.sales_order_detail.sub_total,
          //     "Total + Shipping (Rp)": element.sales_order_detail.sub_total + element.shipping,
          //     "Commission": element.sales_order.platform_commission_rate,
          //     "Payment Type": element.sales_order.payment_type,
          //     "Status": element.status_text,
          //   };
          // });
          const arrData = res.data.data.reduce((acc, curVal, index) => {
            acc.push(this.mapperProductXlsx(index, curVal));
            if (curVal?.bundled_product) acc.push(...curVal?.bundled_product?.map(el => this.mapperBundleXlsx(el)));
            return acc
          }, []);
          const sdate = this.$helper.format.date(this.dateReport[0], "DDMMMYYYY"),
            edate = this.$helper.format.date(this.dateReport[1], "DDMMMYYYY")
          this.$helper.download.downloadXLSX(arrData, `order_report_${sdate}-${edate}`);
        } else this.$helper.toaster.show("Something went wrong", "danger");
        this.isLoadingDownloadXlsx = false;
      } catch (error) {
        console.error(error);
        this.isLoadingDownloadXlsx = false;
      }
    },
    // getTotalPlatformCommission(item) {
    //   return item.platform_commission_rate ? item.sub_total * ((100 - item.platform_commission_rate) / 100) : '-';
    // },
     mapperProductXlsx(index, element) {
      return {
        No: index + 1,
        "No Invoice": element.sales_order_seller?.order_number,
        "Tanggal Penjualan": element.sales_order_seller?.order_date,
        "Nama Barang": element.product_name,
        Color: element.product_var?.color?.name,
        Size: element.product_var?.size?.international,
        "Type Barang": element.product_var?.product?.category?.name,
        Merchant: element.product_var?.product?.brand?.name,
        // Merchant: element.sales_order_seller?.merchant?.full_name,
        Qty: element.quantity,
        "Price Kotor": element.price,
        "Qty*Price Kotor": element.price * element.quantity,
        Diskon: element.final_discount,
        "Qty*Diskon": element.final_discount * element.quantity,
        "(Qty*Price Kotor)-(Qty* Diskon)":
          element.price * element.quantity -
          element.final_discount * element.quantity,
        Voucher: element.voucher_value,
        "Kode Voucher":
          element.used_voucher?.voucher_discount?.voucher_detail?.voucher
            ?.masking,
        "Voucher BP": element.final_voucher_reseller,
        "Kode Voucher BP":
          element.used_voucher?.voucher_reseller?.voucher_detail?.voucher
            ?.masking,
        Ongkir: element.final_shipping,
        "Free Shipping": element.final_free_shipping,
        "Kode Shipping":
          element.used_voucher?.voucher_shipping?.voucher?.masking,
        "Handling Fee": element.handling_fee,
        "Total Payment": element.total_payment,
        "Commission Value": element.platform_commission_value || 0,
        "Commission Rate": element.platform_commission_rate ? `${element.platform_commission_rate}%` : "-" ,
        Source: element.sales_order?.device_type,
        "Payment Method": element.sales_order?.payment_type,
        "Status": element.status_text,
        "Nama Customer": element.sales_order_seller?.member?.full_name,
        "Alamat Customer": element.customer_address,
        Berat: element.product_var?.product?.weight,
        Kadar: element.product_var?.product.kadar,
        "Kasus Invoice": "-",
        Catatan: "-",
      };
    },
    mapperBundleXlsx(element) {
      return {
        No: "-",
        "No Invoice": "Bundle Item",
        "Tanggal Penjualan": "-",
        "Nama Barang": element.product_name,
        Color: element.product_color,
        Size: element.product_size,
        "Type Barang": "-",
        Merchant: "-",
        Qty: 0,
        "Price Kotor": 0,
        "Qty*Price Kotor": 0,
        Diskon: 0,
        "Qty*Diskon": 0,
        "(Qty*Price Kotor)-(Qty* Diskon)": 0,
        Voucher: 0,
        "Kode Voucher": "-",
        "Voucher BP": 0,
        "Kode Voucher BP": "-",
        Ongkir: 0,
        "Free Shipping": 0,
        "Kode Shipping": "-",
        "Handling Fee": 0,
        "Total Payment": 0,
        "Commission Value": 0,
        "Commission Rate": "-",
        Source: "-",
        "Payment Method": "-",
        "Status": "-",
        "Nama Customer": "-",
        "Alamat Customer": "-",
        Berat: 0,
        Kadar: 0,
        "Kasus Invoice": "-",
        Catatan: "-",
      };
    },
    onSelected(value) {
      this.table.selected = value;
    },
    onSortBy(value) {
      this.table.sortBy = value;
      console.log("value", value);
    },
    onSortDesc(value) {
      this.table.sortDesc = value;
    },
    onSearch(value) {
      console.log("search: ", value);
    },
    onAddClick() {
      console.log("onAddClick");
    },
    onChangeCurrentPage(value) {
      this.table.currentPage = value;
      console.log("onChangeCurrentPage", value);
    },
    onChangePerPage(value) {
      this.table.perPage = value;
    },
    async sendEmailVoiceMessage(id) {
      this.table.isLoading = true;
      const data = JSON.parse(JSON.stringify({ id: id }));
      try {
        let res;
        res = await this.$api.orders.sendEmailVoiceMessage(data);
        if (res.status === 200) {
          this.$helper.toaster.show(res.data.message, "success");
        } else {
          this.$helper.toaster.show(res.data.message, "danger");
        }
        this.table.isLoading = false;
      } catch (error) {
        this.table.isLoading = false;
        console.error(error);
        this.$helper.toaster.show(error.response.data.message, "danger");
      }
    },
  },
  watch: {
    search() {
      this.fetchData();
    },
    // selectedFilter() {
    //   this.fetchData();
    // },
    "table.currentPage"() {
      this.fetchData();
    },
    "table.perPage"() {
      this.fetchData();
    },
    "table.sortBy"() {
      this.fetchData();
    },
    "table.sortDesc"() {
      this.fetchData();
    },
  },
};
</script>