<template>
  <b-button
    :type="type || 'button'"
    :size="size ? size : 'sm'"
    :block="block"
    :variant="variant ? variant : 'info'"
    :disabled="isLoading || disabled"
    @click="onClick"
    class="justify-content-between"
  >
    <slot name="prefix" v-if="!isLoading"></slot>
    <font-awesome
      :icon="!loadingIndicator ? 'spinner' : loadingIndicator"
      spin
      v-if="isLoading"
    >
    </font-awesome>
    {{ isLoading ? (!loadingText ? "Loading..." : loadingText) : text }}
    <slot name="suffix" v-if="!isLoading"></slot>
  </b-button>
</template>

<script>
export default {
  props: [
    "type",
    "isLoading",
    "loadingText",
    "text",
    "loadingIndicator",
    "variant",
    "data",
    "block",
    "to",
    "disabled",
    "size",
  ],
  methods: {
    onClick() {
      if (this.to) {
        this.$router.push(this.to);
      }
      this.$emit("onClick", this.data);
    },
  },
};
</script>