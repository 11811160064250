import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './api.js'
import './helper.js'

//? Import bootstrap * css files (order is important)
//* Bootstrap Vue -> bootstrap + icon bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue) //? Make BootstrapVue available throughout your project
Vue.use(IconsPlugin) //? Optionally install the BootstrapVue icon components plugin
//* Bootstrap css
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//* Import css file
import './assets/css/all.css'

//? Font Awesome -> icon fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons' // semua di import
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)
Vue.component('font-awesome', FontAwesomeIcon)
Vue.component('fawesome-pro', () => import('@/components/fontAwesomeMaker/FAwesomeMaker'))

//? Vuelidate -> Form Validation
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

//? Chart -> vue-chartjs
import { Line } from "vue-chartjs";
Vue.component('line-chart', {
  extends: Line,
  props: ["lineChartData"],
  mounted() {
    this.renderChart(this.lineChartData, { responsive: true, maintainAspectRatio: false })
  }
})

//* <my-input-form> component
Vue.component('my-input-form', () => import('./components/form/MyInputForm'))

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
