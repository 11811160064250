export default () => ({
    //? percentage
    // * 1 => 2 = 100%, 2 => 1 = 50%
    percentage: (value, prev) => {
        const result = (Math.abs(100 - (100 * value / prev)));
        if (result % 1 > 0) {
            return result.toFixed(2);
        }
        return result;
    }
    ,

})