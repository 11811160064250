<template>
  <div class="container-fluid">
    <SummaryCard
      :isLoading="isLoading"
      :dashboardDate="dashboardDate"
      :dashboardDatePrevious="dashboardDatePrevious"
      :summaryData="summaryData"
      @onDateChange="onDateChange"
      @onDatePreviousChange="onDatePreviousChange"
    />
    <PerformanceCard
      :isLoading="isLoading"
      :dashboardDate="dashboardDate"
      :performanceData="performanceData"
      @onDateChange="onDateChange"
    />
  </div>
</template>

<script>
import moment from "moment";
const SummaryCard = () => import("./components/SummaryCard.vue");
const PerformanceCard = () => import("./components/PerformanceCard.vue");

export default {
  name: 'Dashboard',
  components: {
    SummaryCard,
    PerformanceCard,
  },
  data() {
    return {
      isLoading: false,
      dashboardDate: [],
      dashboardDatePrevious: [],
      summaryData: null,
      performanceData: null,
    }
  },
  mounted() {
    this.setDefaultDate();
  },
  methods: {
    setDefaultDate() {
      const start = new Date();
      const end = new Date();
      start.setTime(start.getTime() - 29 * 24 * 3600 * 1000);
      this.dashboardDate = [start, end];
    },
    setPreviousDate() {
      if (!this.dashboardDate) return this.dashboardDatePrevious = null;

      const sdateMoment = moment(String(this.dashboardDate[0]));
      const edateMoment = moment(String(this.dashboardDate[1]));
      const diff = edateMoment.diff(sdateMoment, "days");
      const sdatePrev = edateMoment.subtract(1 + diff * 2, "days");
      const edatePrev = sdateMoment.subtract(1, "days");
      this.dashboardDatePrevious = [sdatePrev.toDate(), edatePrev.toDate()];
    },
    async fetchData() {
      this.isLoading = true;
      try {
        const res = await this.$api.dashboard.getData(
          {
            sdate: this.$helper.format.dateAPI(this.dashboardDate[0]),
            edate: this.$helper.format.dateAPI(this.dashboardDate[1]),
            ...(this.dashboardDatePrevious
              ? {
                  sdatePrev: this.$helper.format.dateAPI(this.dashboardDatePrevious[0]),
                  edatePrev: this.$helper.format.dateAPI(this.dashboardDatePrevious[1]),
                }
              : undefined
            ),
          }
        );
        if (res.status === 200) {
          this.summaryData = res.data.data.summary;
          this.performanceData = res.data.data.chart;
        }
         else this.$helper.toaster.show("Something went wrong", "danger");
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.isLoading = false;
      }
    },
    onDateChange(value) {
      this.dashboardDate = value;
    },
    onDatePreviousChange(value) {
      this.dashboardDatePrevious = value;
    }
  },
  watch: {
    dashboardDate() {
      this.setPreviousDate();
    },
    dashboardDatePrevious() {
      this.fetchData();
    },
  },
}
</script>
