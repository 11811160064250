var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('b-card',{staticClass:"shadow border-0"},[_c('h1',{staticClass:"roboto"},[_vm._v("Set Callback URL")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('MyInputFormTextVue',{attrs:{"id":"payment-url","label":"Payment URL","description":"Please set the callback payment URL to get updated payment status","classComponent":{ 'is-invalid': _vm.$v.formData.callback_url_payment.$error },"invalidMessage":[
              !_vm.$v.formData.callback_url_payment.required
                ? 'Payment URL is required'
                : null
            ],"required":""},model:{value:(_vm.$v.formData.callback_url_payment.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.callback_url_payment, "$model", $$v)},expression:"$v.formData.callback_url_payment.$model"}}),_c('MyInputFormTextVue',{attrs:{"id":"order-url","label":"Order URL","description":"Please set the callback order URL to get updated order status","classComponent":{ 'is-invalid': _vm.$v.formData.callback_url_order.$error },"invalidMessage":[
              !_vm.$v.formData.callback_url_order.required
                ? 'Order URL is required'
                : null
            ],"required":""},model:{value:(_vm.$v.formData.callback_url_order.$model),callback:function ($$v) {_vm.$set(_vm.$v.formData.callback_url_order, "$model", $$v)},expression:"$v.formData.callback_url_order.$model"}})],1)],1),_c('div',{staticClass:"d-flex justify-content-end mt-2"},[_c('MyButton',{staticClass:"ml-auto",attrs:{"text":"Save","type":"submit","isLoading":_vm.isLoading}}),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }