<template>
  <div class="d-flex py-2">
    <b-img
      v-bind="mainProps"
      thumbnail
      :src="product_image_url"
      fluid
      :alt="product_name"
      width="100"
      height="100"
    ></b-img>
    <p class="px-2">
      <b> {{ product_name }} </b>
      <template v-if="merchant && merchant.id == -1 && vendor">
        <br />
        <b>Vendor:</b> {{ vendor.full_name }}
      </template>
      <br />
      <b>Size:</b> {{ size }}
      <br />
      <b>Color:</b> {{ color }}
      <br />
      <b>Notes:</b> {{ notes }}
      <br />
      <b>Commission:</b> {{ `${$helper.format.price(platform_commission_value)} (${platform_commission_rate}%)` }}
    </p>
    <span class="ml-auto text-gold font-weight-bold">
      {{ $helper.format.price(price) }}
    </span>
    <span class="ml-2"> x {{ quantity }} </span>
  </div>
</template>

<script>
export default {
  props: [
    "product_name",
    "product_image_url",
    "price",
    "quantity",
    "size",
    "color",
    "notes",
    "merchant",
    "vendor",
    "platform_commission_rate",
    "platform_commission_value"
  ],
  data() {
    // 19524 contoh: pickup point HSO220300035
    // 17524 contoh: banyak merchant HSO211200021
    return {
      mainProps: { width: 80, height: 80, class: "" },
    };
  },
};
</script>
