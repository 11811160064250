<template>
  <div class="p-2 mb-2">
    <b-skeleton class="m-2" height="1.5rem" width="10%"></b-skeleton>
    <div class="row no-gutters align-items-center text-center text-lg-left">
      <div
        class="col col-lg-3 px-2"
        :class="{'border-right' : index < totalCol}"
        v-for="index in totalCol"
        :key="'name-' + index"
      >
        <b-skeleton width="50%"></b-skeleton>
      </div>
    </div>
    <div class="row no-gutters text-center text-lg-left">
      <div
        class="align-items-end d-flex col col-lg-3 px-2 text-grey"
        :class="{'border-right' : index < totalCol}"
        v-for="index in totalCol"
        :key="'value-' + index"
      >
        <b-skeleton width="100%"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["totalCol"],
  data() {
    return {};
  },
};
</script>