<template>
  <div class="container-fluid">
    <b-card class="shadow border-0">
      <h1 class="roboto">Change Password</h1>
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="6">
            <MyInputFormTextVue
              id="old-password"
              label="Old Password"
              :type="old_password_type"
              v-model="$v.formData.old_password.$model"
              :classComponent="{ 'is-invalid': $v.formData.old_password.$error }"
              :invalidMessage="[
                !$v.formData.old_password.required
                  ? 'Old password is required'
                  : null
              ]"
            >
              <template #input-group-append>
                <b-input-group-text class="cursor-pointer" @click="switchVisibility('old_password_type')">
                  <fawesome-pro
                    variant="far"
                    :icon="old_password_type === 'password' ? 'eye' : 'eye-slash'"
                    class="icon-black"
                    alt="Toogle Password Visibility"
                    title="Password Visibility"
                    size="sm"
                  />
                </b-input-group-text>
              </template>
            </MyInputFormTextVue>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <MyInputFormTextVue
              id="new-password"
              label="New Password"
              :type="new_password_type"
              v-model="$v.formData.new_password.$model"
              :classComponent="{ 'is-invalid': $v.formData.new_password.$error }"
              :invalidMessage="[
                !$v.formData.new_password.required
                  ? 'New password is required'
                  : null,
                !$v.formData.new_password.passwordPattern
                  ? 'New password must be at least have 1 letter and 1 number, min 6 characters, no whitespace'
                  : null
              ]"
            >
              <template #input-group-append>
                <b-input-group-text class="cursor-pointer" @click="switchVisibility('new_password_type')">
                  <fawesome-pro
                    variant="far"
                    :icon="new_password_type === 'password' ? 'eye' : 'eye-slash'"
                    class="icon-black"
                    alt="Toogle Password Visibility"
                    title="Password Visibility"
                    size="sm"
                  />
                </b-input-group-text>
              </template>
            </MyInputFormTextVue>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <MyInputFormTextVue
              id="confirm-password"
              label="Confirm Password"
              :type="confirm_password_type"
              v-model="$v.formData.confirm_password.$model"
              :classComponent="{ 'is-invalid': $v.formData.confirm_password.$error }"
              :invalidMessage="[
                !$v.formData.confirm_password.required
                  ? 'Confirm password is required'
                  : null,
                !$v.formData.confirm_password.sameAsPassword
                  ? 'Confirm password must be identical with new password!'
                  : null
              ]"
            >
              <template #input-group-append>
                <b-input-group-text class="cursor-pointer" @click="switchVisibility('confirm_password_type')">
                  <fawesome-pro
                    variant="far"
                    :icon="confirm_password_type === 'password' ? 'eye' : 'eye-slash'"
                    class="icon-black"
                    alt="Toogle Password Visibility"
                    title="Password Visibility"
                    size="sm"
                    @click.native="switchVisibility('#input-confirm-password')"
                  />
                </b-input-group-text>
              </template>
            </MyInputFormTextVue>
          </b-col>
        </b-row>
        
        <div class="d-flex justify-content-end mt-2">
          <MyButton
            text="Save"
            type="submit"
            class="ml-auto"
            :isLoading="isLoading"
          />
          <b-button class="ml-2" variant="secondary" @click="$router.push('/')">
            Cancel
          </b-button>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
const MyButton = () => import("@/components/pages/MyButton.vue");
const MyInputFormTextVue = () => import("@/components/form/MyInputFormText.vue");
import { required, sameAs, helpers } from "vuelidate/lib/validators";

//* Must have min 1 letter & 1 number (alphanumeric), min 6 character, no whitespace, symbol(optional)
const passwordPattern = helpers.regex("passwordPattern", /^(?=[\x21-\x7E]*[0-9])(?=[\x21-\x7E]*[a-z])[\x21-\x7EA-Za-z0-9]*.{5,}\S$/);

export default {
  name: "ChangePassword",
  components: {
    MyButton,
    MyInputFormTextVue,
  },
  data() {
    return {
      isLoading: false,
      formData: {},

      //* Input type attribute for all password form
      old_password_type: 'password',
      new_password_type: 'password',
      confirm_password_type: 'password',
    };
  },
  validations() {
    return {
      formData: {
        old_password: {
          required,
        },
        new_password: {
          required,
          passwordPattern
        },
        confirm_password: {
          required,
          sameAsPassword: sameAs("new_password"),
        },
      },
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.formData = {
        old_password: null,
        new_password: null,
        confirm_password: null,
      };
    },
    
    async onSubmit() {
      try {
        this.isLoading = true;
        this.$v.$touch();
        if (this.$v.$error) {
          this.isLoading = false;
          return this.$helper.toaster.show("Please fill in the form correctly", "danger");
        }

        const res = await this.$api.user.changePassword(this.formData);
        if (res.status === 200) {
          this.$helper.toaster.show(res.data.message, "success"); 
          this.$router.push('/');
        } else this.$helper.toaster.show(res.data.message, "danger"); 
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }

    },
    //* Ref : https://simedia.tech/blog/show-hide-password-input-values-with-vue-js/
    switchVisibility(inputType) {
      this.$data[inputType] = this.$data[inputType] === 'password' ? 'text' :'password';
    },
  },
};
</script>