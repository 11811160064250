<template>
  <form
    @submit.prevent="onSearch"
    class="input-search ml-lg-auto flex-grow-1 flex-lg-grow-0 rounded"
  >
    <input
      id="search"
      type="search"
      :placeholder="placeholder"
      class="form-control"
      v-model="model"
    />
    <button class="btn">
      <fawesome-pro
        variant="far"
        icon="search"
        class="icon-black"
        alt="Search"
        title="Search"
      />
    </button>
  </form>
</template>

<script>
export default {
  props: ["value", "placeholder"],
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    onSearch() {
      // const el = document.getElementById("search");
      this.$emit("input", this.model);
      // this.$emit("onSearch", el.value);
    },
  },
  watch: {
    value(newValue) {
      this.model = newValue;
    },
  },
};
</script>
<style scoped>
.input-search {
  display: flex;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .5rem;
}

.input-search > input {
  background: transparent;
  border: none
}
</style>