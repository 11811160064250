<template>
  <section class="d-flex flex-column min-vh-100">
    <template v-if="isShowNavBar">
      <Header />
      <div class="row no-gutters flex-grow-1">
        <div class="col-2 d-none d-lg-block">
          <Sidebar />
        </div>
        <div class="col py-3 body-color">
          <router-view />
        </div>
      </div>
      <Footer />
    </template>
    <template v-else>
      <router-view />
    </template>
  </section>
</template>
<script>
const Header = () => import('@/components/navs/Header');
const Sidebar = () => import('@/components/navs/Sidebar');
const Footer = () => import('@/components/navs/Footer');

export default {
  components: {
    Header,
    Sidebar,
    Footer
  },
  computed: {
    isShowNavBar() {
      return !/login|logout/i.test(this.$route.name);
    },
  }
}
</script>

<style>
</style>
