<template>
  <div class="container-fluid">
    <h1>Sales Order Detail</h1>
    <b-card class="mt-2">
      <h2 class="mb-2">Detail Order</h2>
      <ShimmerLabelCustomer :total="4" />
    </b-card>
    <b-card class="mt-2">
      <h2 class="mb-2">Detail Product</h2>
      <b-card class="mt-2">
        <div class="row">
          <div class="col">
            <b-skeleton height="1.5rem" width="30%"></b-skeleton>
          </div>
          <div class="col text-right">
            <div class="row mb-1">
              <b-skeleton
                class="ml-auto"
                height="1rem"
                width="20%"
              ></b-skeleton>
            </div>
            <div class="row">
              <b-skeleton
                class="ml-auto"
                height="1rem"
                width="30%"
              ></b-skeleton>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <div class="row py-2">
            <div class="col-2">
              <b-skeleton-img aspect="1:1"></b-skeleton-img>
            </div>
            <div class="col-6 m-0 p-0">
              <b-skeleton height="1rem" width="100%"></b-skeleton>
            </div>
            <div class="col m-0 p-0">
              <b-skeleton
                class="ml-auto"
                height="1rem"
                width="40%"
              ></b-skeleton>
            </div>
          </div>
          <!-- <CustomerProductCard
            v-for="salesOrderDetail in salesOrderSeller.sales_order_details"
            :key="salesOrderDetail.id"
            :product_name="salesOrderDetail.product_name"
            :price="salesOrderDetail.price"
            :quantity="salesOrderDetail.quantity"
            :product_image_url="salesOrderDetail.product_thumbnail_url"
          /> -->
        </div>
      </b-card>
    </b-card>
    <div class="row mt-2">
      <b-col>
        <b-card>
          <h2 class="mb-2">Shipping Address</h2>
          <b-skeleton
            v-for="index in 7"
            :key="'shipping-address' + index"
            height="1rem"
            width="100%"
            class="mb-1"
          ></b-skeleton>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h2 class="mb-2">Payment Address</h2>
          <b-skeleton
            v-for="index in 7"
            :key="'payment-address' + index"
            height="1rem"
            width="100%"
            class="mb-1"
          ></b-skeleton>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h2 class="mb-2">Total</h2>
          <b-row class="mt-2">
            <b-col>Sub Total</b-col>
            <b-col class="text-right">
              <b-skeleton height="1rem" width="100%" class="mb-2"></b-skeleton>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>Discount</b-col>
            <b-col class="text-right">
              <b-skeleton height="1rem" width="100%" class="mb-2"></b-skeleton>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>Shipping</b-col>
            <b-col class="text-right">
              <b-skeleton height="1rem" width="100%" class="mb-2"></b-skeleton>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>Loyalty Point</b-col>
            <b-col class="text-right">
              <b-skeleton height="1rem" width="100%" class="mb-2"></b-skeleton>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>Handling Fee</b-col>
            <b-col class="text-right">
              <b-skeleton height="1rem" width="100%" class="mb-2"></b-skeleton>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col>Total</b-col>
            <b-col class="text-right">
              <b-skeleton height="1.4rem" width="100%" class="mb-2"></b-skeleton>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </div>
  </div>
</template>

<script>
import ShimmerLabelCustomer from "./ShimmerLabelCustomer.vue";
export default {
  components: { ShimmerLabelCustomer },
  data() {
    return {};
  },
};
</script>