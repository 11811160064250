import Vue from "vue";
import format from "@/helper/format";
import cookie from "@/helper/cookie";
import toaster from "@/helper/toaster";
import download from "@/helper/download";
import report from "@/helper/report";
import other from "@/helper/other";

export const helperFactories = {
  format: format(),
  cookie: cookie(),
  toaster: toaster(),
  download: download(),
  report: report(),
  other: other(),
}

Vue.prototype.$helper = helperFactories;