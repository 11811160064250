<template>
  <div class="container-fluid">
    <b-card class="shadow border-0">
      <h1 class="roboto">Set Callback URL</h1>
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="8">
            <MyInputFormTextVue
              id="payment-url"
              label="Payment URL"
              description="Please set the callback payment URL to get updated payment status"
              v-model="$v.formData.callback_url_payment.$model"
              :classComponent="{ 'is-invalid': $v.formData.callback_url_payment.$error }"
              :invalidMessage="[
                !$v.formData.callback_url_payment.required
                  ? 'Payment URL is required'
                  : null
              ]"
              required
            />
            <MyInputFormTextVue
              id="order-url"
              label="Order URL"
              description="Please set the callback order URL to get updated order status"
              v-model="$v.formData.callback_url_order.$model"
              :classComponent="{ 'is-invalid': $v.formData.callback_url_order.$error }"
              :invalidMessage="[
                !$v.formData.callback_url_order.required
                  ? 'Order URL is required'
                  : null
              ]"
              required
            />
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-2">
          <MyButton
            text="Save"
            type="submit"
            class="ml-auto"
            :isLoading="isLoading"
          />
          <b-button class="ml-2" variant="secondary" @click="$router.push('/')">
            Cancel
          </b-button>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
const MyButton = () => import("@/components/pages/MyButton.vue");
const MyInputFormTextVue = () => import("@/components/form/MyInputFormText.vue");
import { required } from "vuelidate/lib/validators";

export default {
  name: "CallbackUrl",
  components: {
    MyButton,
    MyInputFormTextVue,
  },
  data() {
    return {
      isLoading: false,
      formData: {},
    };
  },
  validations() {
    return {
      formData: {
        callback_url_payment: { required },
        callback_url_order: { required },
      },
    };
  },
  mounted() {
    this.initData();
    this.getDetailPlatform();
  },
  methods: {
    initData() {
      this.formData = {
        callback_url_payment: null,
        callback_url_order: null
      };
    },
    async getDetailPlatform() {
      try {
        const res = await this.$api.user.detail();
        if (res.status === 200) Object.assign(this.formData, this.$helper.other.updateFormData(this.formData, res.data.data));
        else this.$helper.toaster.show(res.data.message, "danger");
      } catch (error) {
        console.error(error);
      }
    },
    async onSubmit() {
      try {
        this.isLoading = true;
        this.$v.$touch();
        if (this.$v.$error) {
          this.isLoading = false;
          return this.$helper.toaster.show("Please fill in the form correctly", "danger");
        }
        const res = await this.$api.user.updateCallbackUrl(this.formData);
        if (res.status === 200) {
          this.$helper.toaster.show(res.data.message, "success"); 
          this.$router.push('/');
        } else this.$helper.toaster.show(res.data.message, "danger"); 
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }

    },
  },
};
</script>