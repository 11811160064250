<template>
  <router-link v-if="to" :to="to">
    <span v-if="icon" :class="`${(variant ? variant : 'fa')} fa-${icon}${(size ? ' fa-' + size : '')}${to ? ' cursor-pointer' : ''}`"></span>
  </router-link>
  <span v-else :class="`${(variant ? variant : 'fa')} fa-${icon}${(size ? ' fa-' + size : '')}${to ? ' cursor-pointer' : ''}`"></span>
</template>
<script>
export default {
  name: "FontAwesomeMaker",
  props: ["icon", "size", "variant", "to"],
  mounted() {
    if (!this.icon) console.error('Missing required prop: "icon"');
  }
}
</script>
