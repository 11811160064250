<template>
  <div class="container py-md-3">
    <ul class="sidebar">
      <template v-for="(nav, i) in navigations" >
        <li v-if="!nav.child.length" :key="i">
          <router-link exact :to="nav.to" :key="i">
            {{ nav.label }}
          </router-link>
        </li>
        <template v-else>
          <li
            v-b-toggle="'collapse-' + nav.label.split(' ')[0].toLowerCase()"
            :key="'parent' + i"
          >
            <span>{{ nav.label }}</span>
          </li>
          <b-collapse
            :id="'collapse-' + nav.label.split(' ')[0].toLowerCase()"
            :key="i"
          >
            <li v-for="(navChild, j) in nav.child.filter((el) => !el.disabled)" :key="j">
              <router-link :to="navChild.to">
                {{ navChild.label }}
              </router-link>
            </li>
          </b-collapse>
        </template>
      </template>
      <li>
        <router-link to="/logout" class="d-md-none">Logout</router-link>
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  name: "Sidebar",
  data() {
    return {
      trxCount: 0,
      navigations: [
        {
          disabled: false,
          label: "Dashboard",
          key: "dashboard",
          to: "/",
          child: [],
        },
        {
          disabled: false,
          label: "Order",
          to: null,
          child: [
            {
              label: "Order List",
              to: "/orders",
            },
            {
              label: "Order Report",
              to: "/reports",
            },
          ],
        },
      ],
    };
  },
};
</script>