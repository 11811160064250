<template>
  <div>
    <b-row
      v-for="index in total"
      :key="'shimmer-loading-label-customer-' + index"
      class="my-2"
    >
      <b-col cols="2">
        <b>
          <b-skeleton height="1rem"></b-skeleton>
        </b>
      </b-col>
      <b-col><b-skeleton height="1rem" width="100%"></b-skeleton> </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["total"],
};
</script>