<template>
  <div>
    <slot name="prefix"></slot>
    <slot name="label"></slot>
    <date-picker
      v-model="model"
      :formatter="valueType ? null : momentFormat"
      :shortcuts="showShortcuts && isRange ? shortcuts : []"
      :disabled-date="disabledAfterToday"
      :type="type"
      :range="isRange"
      :placeholder="placeholder"
      :disabled="disabled"
      :value-type="valueType"
    ></date-picker>
    <slot name="suffix"></slot>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  name: "MyDatePicker",
  components: { DatePicker },
  props: [
    "value",
    "type",
    "showShortcuts",
    "isRange",
    "placeholder",
    "disabled",
    "afterToday",
    "format",
    "valueType",
  ],
  data() {
    return {
      model: this.value,
      momentFormat: {
        stringify: (date) => {
          return this.$helper.format.date(date, this.format ?? "D MMM YYYY");
        },
      },
      shortcuts: [
        {
          text: "To Day",
          onClick: () => {
            const date = [new Date(), new Date()];
            this.model = date;
          },
        },
        {
          text: "Yesterday",
          onClick: () => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            this.model = [date, date];
          },
        },
        {
          text: "Last 3 Days",
          onClick: () => {
            this.lastDays(3);
          },
        },
        {
          text: "Last 7 Days",
          onClick: () => {
            this.lastDays(7);
          },
        },
        {
          text: "Last 30 Days",
          onClick: () => {
            this.lastDays(30);
          },
        },
        {
          text: "This Month",
          onClick: () => {
            this.lastMonth(0);
          },
        },
        {
          text: "Last Month",
          onClick: () => {
            this.lastMonth(1);
          },
        },
      ],
    };
  },
  methods: {
    disabledAfterToday(date) {
      if (this.afterToday !== undefined) return false;
      return date > new Date().setHours(0, 0, 0, 0);
    },
    lastDays(day) {
      const start = new Date();
      const end = new Date();
      start.setTime(start.getTime() - (day - 1) * 24 * 3600 * 1000);
      const date = [start, end];
      this.model = date;
    },
    lastMonth(month) {
      const now = new Date();
      const start = new Date(now.getFullYear(), now.getMonth() - month, 1);
      const end = new Date(now.getFullYear(), now.getMonth() + 1 - month, 0);
      const date = [start, end];
      this.model = date;
    },
  },
  watch: {
    model(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      if (newValue != this.model) this.model = newValue;
    },
  },
};
</script>
<style scoped>
.mx-datepicker >>> .mx-input {
  font-size: 1rem;
  height: 38px;
}
</style>