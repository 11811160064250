<template>
  <footer class="px-4 py-3 bg-dark">
    Copyright &copy; {{ new Date().getUTCFullYear() }} Whizliz.com. All rights reserved
  </footer>
</template>
<script>
export default {
  name: "Footer"
}
</script>
