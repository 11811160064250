<template>
  <b-skeleton-wrapper :loading="isLoading">
    <template #loading>
      <b-card body-class="p-0" class="shadow border-0">
        <div class="d-flex px-3 pt-3 pb-2">
          <h2 class="roboto mb-0">Summary</h2>
          <b-skeleton
            class="m-1 ml-auto"
            type="input"
            height="2rem"
            width="30%"
          ></b-skeleton>
        </div>
        <PanelInfoShimmer :totalCol="3" />
      </b-card>
    </template>

    <b-card body-class="p-0" class="shadow border-0">
      <div class="d-flex px-3 pt-3 pb-2">
        <h2 class="roboto mb-0">Summary</h2>
        <MyDatePicker
          class="ml-auto"
          v-model="dateModel"
          :showShortcuts="true"
          :isRange="true"
        >
          <template #label> Period: </template>
        </MyDatePicker>
      </div>
      <MyDatePicker
        class="text-right px-3 pb-3"
        v-model="datePreviousModel"
        :showShortcuts="true"
        :isRange="true"
      >
        <template #label> Compare: </template>
      </MyDatePicker>

      <!-- <hr> -->
      <PanelInfo :data="summaryData" />
    </b-card>
  </b-skeleton-wrapper>
</template>

<script>
const PanelInfoShimmer = () => import("./PanelInfoShimmer.vue");
const PanelInfo = () => import("./PanelInfo.vue");

const MyDatePicker = () => import("@/components/form/MyDatePicker.vue");
export default {
  name: "SummaryCard",
  components: {
    PanelInfoShimmer,
    PanelInfo,
    MyDatePicker
  },
  props: ["isLoading", "dashboardDate", "dashboardDatePrevious", "summaryData"],
  computed: {
    dateModel: {
      get() {
        return this.dashboardDate;
      },
      set(value) {
        this.$emit("onDateChange", value)
      }
    },
    datePreviousModel: {
      get() {
        return this.dashboardDatePrevious;
      },
      set(value) {
        this.$emit("onDatePreviousChange", value)
      }
    },
  }
  
};
</script>