<template>
  <b-row class="my-2">
    <b-col cols="3">
      <b>{{ name }}</b>
    </b-col>
    <b-col>: {{ value }}</b-col>
  </b-row>
</template>

<script>
export default {
  props: ["name", "value"],
};
</script>