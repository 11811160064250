const XLSX = require("xlsx");
export default () => ({
    downloadXLSX: (arrData, fileName = "report", sheetName = "Sheet1") => {
        // https://lifesaver.codes/answer/how-to-simply-export-a-worksheet-to-xlsx
        /* make the worksheet */
        const ws = XLSX.utils.json_to_sheet(arrData);
        /* add to workbook */
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        /* generate an XLSX file */
        XLSX.writeFile(wb, fileName + ".xlsx");
    }
})

