import link from "./link";

export default (axios, headers) => ({
  async getData(params) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + link.order.index, { params: params, ...headers() });
    } catch (error) {
      console.error(error)
      return error.response;
    }
  },
  async getDetailData(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + link.order.detail + `/${id}`, headers());
    } catch (error) {
      console.error(error)
      return error.response;
    }
  },

})