<template>
  <div>
    <slot name="header"></slot>
    <b-table
      :items="tableItems"
      :fields="tableFields"
      :per-page="tablePerPage"
      :busy="tableBusy"
      select-mode="multi"
      :selectable="tableSelectable ? tableSelectable : false"
      @row-selected="onRowSelected"
      @row-clicked="onRowClicked"
      @head-clicked="onHeadClicked"
      :sort-by.sync="tableSortBy"
      :sort-desc.sync="tableSortDesc"
      ref="myTable"
      responsive
      show-empty
      hover
      striped
      no-sort-reset
      sort-icon-left
      :selected-variant="!selectedVariant ? 'selected-custom' : selectedVariant"
      tbody-tr-class="table-row-style"
      thead-tr-class="border-bottom th-v-align"
      borderless
    >
      <!-- :selected-variant="!selectedVariant ? 'secondary' : selectedVariant"
      :tbody-tr-class="rowClass" -->
      <template #empty="scope">
        <div class="text-center">
          <h4>{{ scope.emptyText }}</h4>
        </div>
      </template>
      <template #table-busy>
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <!-- A custom formatted header cell for field 'name' -->
      <!-- <template #head(selected)="data"> -->
      <template #head(selected)>
        <span
          v-if="tableSelected.length == tableItems.length"
          aria-hidden="true"
          @click="clearSelected"
        >
          <fawesome-pro
            variant="far"
            icon="check-square"
            class="icon-black selected-symbols cursor-pointer"
            alt="Selected"
            title="Selected"
            size="sm"
          />
        </span>
        <span v-else aria-hidden="true" @click="selectAllRows">
          <fawesome-pro
            variant="far"
            icon="square"
            class="icon-black selected-symbols cursor-pointer"
            alt="Not selected"
            title="Not selected"
            size="sm"
          />
        </span>
      </template>

      <template #cell(selected)="{ rowSelected }">
        <div class="text-center">
          <template v-if="rowSelected">
            <!-- <span aria-hidden="true" class="selected-symbols">&check;</span> -->
            <span aria-hidden="true">
              <fawesome-pro
                variant="far"
                icon="check-square"
                class="text-gold selected-symbols cursor-pointer"
                alt="Selected"
                title="Selected"
                size="sm"
              />
            </span>
          </template>
          <template v-else>
            <span aria-hidden="true">
              <fawesome-pro
                variant="far"
                icon="square"
                class="icon-black selected-symbols cursor-pointer"
                alt="Not selected"
                title="Not selected"
                size="sm"
              />
            </span>
          </template>
        </div>
      </template>

      <template #head()="data">
        <slot
          :name="`header_${data.field.key}`"
          v-if="data.field.custom_header"
          v-bind="data"
        ></slot>
        <template v-else>{{ data.label }}</template>
      </template>

      <template #cell()="data">
        <slot
          :name="data.field.key"
          v-if="data.field.custom_column"
          v-bind="data"
        ></slot>
        <template v-else>{{ data.value }}</template>
      </template>
    </b-table>
    <div class="container-fluid d-md-flex">
      <div class="form-group flex-grow-1">
        <label for="initial-per-page" class="mb-0">Show</label>
        <select
          id="initial-per-page"
          class="form-control d-inline w-auto p-0 mx-1"
          v-model="tablePerPage"
        >
          <option
            v-for="num in listPerPage"
            :key="num.value"
            :value="num.value"
          >
            {{ num.text }}
          </option>
        </select>
        <span>of {{ tableTotalRows }} entries</span>
      </div>
      <!-- <b-form-group
        label="Show: "
        label-for="initial-per-page"
        label-cols="3"
        label-align-sm="right"
        class="mb-0"
      >
        <b-form-select
          id="initial-per-page"
          class="col"
          v-model="tablePerPage"
          :options="listPerPage"
        ></b-form-select>
        <span class="col-auto"> entries</span>
      </b-form-group> -->
      <b-pagination
        class="justify-content-center"
        v-model="tableCurrentPage"
        :total-rows="tableTotalRows"
        :per-page="tablePerPage"
      />
    </div>
  </div>
</template>

<script>
export default {
  // slot: https://www.smashingmagazine.com/2019/07/using-slots-vue-js/
  props: [
    "fields",
    "items",
    "currentPage",
    "perPage",
    "totalRows",
    "busy",
    "selected",
    "selectable",
    "sortBy",
    "sortDesc",
    "selectedVariant",

    "defaultValue", //* All selected table row from parent component
  ],
  data() {
    return {
      tableFields: this.fields,
      listPerPage: [
        {
          value: 5,
          text: "5",
        },
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
    };
  },
  computed: {
    tableBusy() {
      return this.busy;
    },
    tableItems: {
      get: function () {
        return this.items;
      },
    },
    // tableItems() {
    //   return this.items;
    // },
    tableSelectable() {
      return this.selectable;
    },
    tableTotalRows() {
      return this.totalRows;
    },
    tableCurrentPage: {
      get: function () {
        return this.currentPage;
      },
      set: function (value) {
        this.$emit("onChangeCurrentPage", value);
      },
    },
    tablePerPage: {
      get: function () {
        return this.perPage;
      },
      set: function (value) {
        this.$emit("onChangePerPage", value);
      },
    },
    tableSelected: {
      get: function () {
        return this.selected;
      },
      set: function (value) {
        this.$emit("onSelected", value);
      },
    },
    tableSortBy: {
      get: function () {
        return this.sortBy;
      },
      set: function (value) {
        this.$emit("onSortBy", value);
      },
    },
    tableSortDesc: {
      get: function () {
        return this.sortDesc;
      },
      set: function (value) {
        this.$emit("onSortDesc", value);
      },
    },
  },
  methods: {
    onRowSelected(items) {
      this.tableSelected = items;
    },
    selectAllRows() {
      this.$refs.myTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.myTable.clearSelected();
    },
    onRowClicked(item, index, event) {
      this.$emit("onRowClicked", item, index, event);
    },
    onHeadClicked(key, field, event) {
      this.$emit("onHeadClicked", key, field, event);
    },
    // rowClass(item, type) {
    //   if (this.stripedClass) {
    //     if (!item || type !== "row") return;
    //     if (item.age > 30) return "table-success";
    //   }
    // },
    // parseData() {
    //   this.table = {
    //     fields: this.fields,
    //     items: this.items,
    //     busy: this.busy,
    //     selected: this.selected,
    //     selectable: this.selectable,
    //     sortBy: this.sortBy,
    //     sortDesc: this.sortDesc,
    //   };
    // },
    checkSelectedRow() {
      if (!this.defaultValue) return;
      for (let i = 0; i < this.tableItems.length; i++) {
        if (!this.defaultValue.find((el) => el.id == this.tableItems[i].id))
          continue;
        setTimeout(() => {
          this.$refs.myTable.selectRow(i);
        }, 1);
      }
    },
  },
  watch: {
    //* On table items change, check if there's selected table row from 'defaultValue' props
    tableItems() {
      this.checkSelectedRow();
    },
    // $props: {
    //   handler() {
    //     this.parseData();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>

<style scoped>
.selected-symbols {
  font-size: 16px;
}
* >>> .table thead th {
  vertical-align: middle !important;    
}
/* .table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: red;
} */
/* .table-striped tbody tr:nth-of-type(odd) { */
/* background-color: rgba(35, 50, 166, 0.05) !important; */
/* } */
/* .table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(111, 69, 178, 0.05);
} */
</style>