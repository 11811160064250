export default {
  dashboard: {
    index: "platform/dashboard"
  },
  user: {
    login: "oapi/session/sign_in",
    logout: "platform/session/sign_out",
    detail: "platform/auth/detail",
    changePassword: "platform/auth/change_password",
    updateCallbackUrl: "platform/auth/callback_url"
  },
  order: {
    index: "platform/orders",
    detail: "platform/orders/detail",
  },
  orderReport: {
    index: "platform/reports",
  }
}

