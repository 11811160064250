<template>
  <section class="landing-page">
    <div class="container-fluid">
      <div class="row h-100 align-items-center">
        <div
          class="
            col-md-7
            d-none d-lg-flex
            align-items-center
            justify-content-center
            text-white
          "
        >
          <div>
            <img
              src="@/assets/img/logomark_white_background.svg"
              height="100"
              class="mb-4"
            />
            <h1 class="roboto">Whizliz Platform Dashboard</h1>
            <h2 class="roboto subtitle">
              Whizliz Platform Dashboard
            </h2>
          </div>
        </div>
        <div
          id="login-form"
          class="col-md-5 d-flex justify-content-center align-items-center"
        >
          <div class="w-100">
            <i><h2 class="m-0">Sign in</h2></i>
            <small>Please input your information below to proceed</small>
            <div class="row justify-content-center pt-4">
              <div class="col-md-8">
                <b-form @submit.prevent="onSubmit">
                  <my-input-form :inputs="loginForm" v-model="$v" />
                  <div
                    class="alert alert-danger text-center"
                    v-if="errorMessage"
                  >
                    <p class="my-2">{{ errorMessage }}</p>
                  </div>
                  <div class="col-md-6 mx-auto">
                    <MyButtonVue
                      type="submit"
                      text="Log In"
                      variant="primary"
                      :isLoading="isLoading"
                      block
                    />
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
const MyButtonVue = () => import("@/components/pages/MyButton.vue");
import { required } from "vuelidate/lib/validators";

export default {
  name: "Login",
  components: {
    MyButtonVue
  },
  data() {
    return {
      email: null,
      password: null,
      //! UMUM
      isLoading: false,
      errorMessage: null,

      loginForm: [
        [
          { id: 'email', label: 'Email', type: 'email', placeholder: 'Email', value: this.email },
          { id: 'password', label: 'Password', type: 'password', placeholder: 'Password', value: this.password },
        ]
      ]
    };
  },
  validations() {
    return {
      email: { required },
      password: { required },
    }
  },
  async mounted() {
    setTimeout(() => {
      document
        .getElementById("login-form")
        .scrollIntoView({ behavior: "smooth" });
    }, 3000);
  },
  methods: {
    async onSubmit() {
      try {
        this.isLoading = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.isLoading = false;
          return this.$helper.toaster.show("Please fill the form correctly", "danger"); 
        }
        const response = await this.$api.user.login({
          email: this.email,
          password: this.password,
        });
        if (response.status === 200 && response.data.data) this.$router.push("/");
        else this.errorMessage = response.data.message;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.isLoading = false;
      }
    },
  },
};
</script>