<template>
  <b-skeleton-wrapper :loading="isLoading">
    <template #loading>
      <b-card body-class="p-0" class="shadow border-0">
        <div class="d-flex px-3 pt-3 pb-2">
          <h2 class="roboto mb-0">Performance</h2>
          <b-skeleton
            class="m-1 ml-auto"
            type="input"
            height="2rem"
            width="30%"
          ></b-skeleton>
        </div>
        <PanelInfoShimmer :totalCol="3" />
      </b-card>
    </template>

    <b-card body-class="p-0" class="shadow border-0 mt-4 position-relative" v-if="performanceData">
      <div class="d-flex px-3 pt-3 pb-2">
        <h2 class="roboto mb-0">Performance</h2>
        <MyDatePicker
          class="ml-auto"
          v-model="dateModel"
          :showShortcuts="true"
          :isRange="true"
        >
          <template #label> Period: </template>
        </MyDatePicker>
      </div>
      <line-chart
        v-if="chartOrder"
        :lineChartData="chartOrder"
        class="px-3 mb-3"
      />
      <line-chart
        v-if="chartTransaction"
        :lineChartData="chartTransaction"
        class="px-3 mb-3"
      />
    </b-card>
  </b-skeleton-wrapper>
</template>

<script>
const PanelInfoShimmer = () => import("./PanelInfoShimmer.vue");

const MyDatePicker = () => import("@/components/form/MyDatePicker.vue");
export default {
  name: "PerformanceCard",
  components: {
    PanelInfoShimmer,
    MyDatePicker
  },
  props: ["isLoading", "dashboardDate", "performanceData"],
  computed: {
    dateModel: {
      get() {
        return this.dashboardDate;
      },
      set(value) {
        this.$emit("onDateChange", value)
      }
    },
    chartOrder() {
      return {
        labels: this.performanceData.order.map((el) => el.label),
        datasets: [
          {
            label: "Order",
            backgroundColor: "rgba(211, 251, 199, 0.3)",
            borderColor: "rgba(211, 251, 199, 1)",
            borderWidth: 2,
            data: this.performanceData.order.map((el) => el.value),
          },
        ],
      } 
    },  
    chartTransaction() {
      return {
        labels: this.performanceData.transaction.map((el) => el.label),
        datasets: [
          {
            label: "Transaction",
            backgroundColor: "rgba(211, 251, 199, 0.3)",
            borderColor: "rgba(211, 251, 199, 1)",
            borderWidth: 2,
            data: this.performanceData.transaction.map((el) => el.value),
          },
          {
            label: "Commission",
            backgroundColor: "rgba(255, 99, 132, 0.3)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 2,
            data: this.performanceData.platform_commission.map((el) => el.value),
          },
        ],
      } 
    },
  }
  
};
</script>