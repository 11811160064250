<template>
  <div class="row">
    <div class="col-md" v-for="(inputs, c) in inputs" :key="`col-${c}`">
      <div class="row align-items-center">
        <div :class="inp.class ?? 'col-12'" v-for="(inp, i) in inputs" :key="i">
          <div class="form-group">
            <label class="mr-2" :for="inp.id">{{ inp.label }} <span v-if="model[inp.id].required !== undefined" class="text-danger">*</span></label>
            <select :required="model[inp.id].required"
              v-if="inp.type == 'select'"
              :id="inp.id"
              class="form-control"
              :class="{
                'border-danger': model[inp.id].$dirty && model[inp.id].$invalid,
                'border-success': model[inp.id].$dirty && !model[inp.id].$invalid,
              }"
              v-model="model[inp.id].$model"
              @blur="model[inp.id].$touch()"
            >
              <option v-if="inp.placeholder" value="null" disabled>{{ inp.placeholder }}</option>
              <option v-for="(opt, j) in inp.options" :key="`option-${j}`" :value="opt.value">{{ opt.text }}</option>
            </select>
            <textarea :required="model[inp.id].required" rows="4"
              v-else-if="inp.type == 'textarea'"
              :id="inp.id"
              class="form-control" 
              :class="{
                'border-danger': model[inp.id].$dirty && model[inp.id].$invalid,
                'border-success': model[inp.id].$dirty && !model[inp.id].$invalid,
              }"
              v-model="model[inp.id].$model"
              :placeholder="inp.placeholder"
              @blur="model[inp.id].$touch()"
            ></textarea>
            <date-picker :required="model[inp.id].required"
              v-else-if="/date|time/.test(inp.type)"
              :id="inp.id" 
              class="w-100 border rounded-less"
              input-class="form-control border-0"
              :class="{
                'border-danger': model[inp.id].$dirty && model[inp.id].$invalid,
                'border-success': model[inp.id].$dirty && !model[inp.id].$invalid,
              }"
              :type="inp.type"
              v-model="model[inp.id].$model"
              :placeholder="inp.placeholder"
              format="D MMMM YYYY"
              value-type="YYYY-MM-DD"
              :disabled-date="disabledDateRange(inp.min, inp.max, date)"
              @blur="model[inp.id].$touch()"
            />
            <template v-else-if="inp.type == 'checkbox'">
              <input
                :id="inp.id"
                v-model="model[inp.id].$model"
                type="checkbox" 
                @blur="model[inp.id].$touch()"
              > {{ inp[model[inp.id].$model] ?? inp.false }}
            </template>
            <template v-else-if="inp.type == 'radio'">
              <div class="d-md-inline-flex align-items-center mx-2" v-for="(opt, j) in inp.options" :key="`option-${j}`">
                <input :required="model[inp.id].required"
                  :id="inp.id"
                  type="radio"
                  class="form-control w-auto h-auto mr-1 d-inline"
                  v-model="model[inp.id].$model"
                  :name="inp.id"
                  :value="opt.value"
                  @blur="model[inp.id].$touch()"
                >
                <span class="text-nowrap w-100">{{ opt.text }}</span>
              </div>
            </template>
            <input :required="model[inp.id].required"
              v-else 
              :id="inp.id" v-model="model[inp.id].$model" 
              class="form-control"
              :class="{
                'border-danger': model[inp.id].$dirty && model[inp.id].$invalid,
                'border-success': model[inp.id].$dirty && !model[inp.id].$invalid,
              }"
              :type="inp.type ?? 'text'" 
              :placeholder="inp.placeholder"
              :maxlength="inp.maxlength"
              :pattern="inp.pattern"
              @blur="model[inp.id].$touch()"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import "vue2-datepicker/index.css";

export default {
  name: 'MyInputForm',
  props: ['value', 'inputs'],
  components: {
    DatePicker,
  },
  data() {
    return {
      model: this.value
    }
  },
  methods: {
    submitData() {
      this.$emit('submit', this.model)
    },
    disabledDateRange(min, max, callback) {
      return callback(date => {
        return date < min || date > max
      })
    },
    date(date) {
      return date
    }
  },
  watch: {
    value(newVal) {
      this.model = newVal
    }
  }
}
</script>
<style scoped>
</style>
